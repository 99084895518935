import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Loading, StreakBar} from '../../components';
import {getApplicationDirection} from '../../components/Utility';
import useAuth from '../../hooks/useAuth';
import useToken from '../../hooks/useToken';
import {url} from '../../routes/utility';
import {getMemberType} from '../../services/Auth/AuthService';
import {DashboardCandidate, DashboardInactiveStudent, DashboardStudent} from "./index";
import useMemberSurvey from "../../hooks/useMemberSurvey";
import {DemoNps} from '../../popups';

const Dashboard = () => {

  const { member } = useAuth();
  const tokenHook = useToken();
  const navigate = useNavigate();
  const memberType = getMemberType(member.Status);
  const language = useSelector(state => state.localizationStore.language);
  const { survey, surveyLoading } = useMemberSurvey();

  useEffect(() => {
    if(!surveyLoading) {
      // Eski ikinci onboarding sorusu artık ilk adıma denk geldiği için ilk onboardinge yönlendiriyoruz.
      if (survey?.second.length === 0) {
        navigate(url("onboarding.first"));
      }
    }
  }, [surveyLoading, survey])

  useEffect(() => {
    //If the member has created a new term, we need to update the token.
    member && tokenHook?.generateToken(member?.MemberId);
  }, []);

  return (
    <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
      {surveyLoading && <Loading />}
      {!surveyLoading && (
          <>
            {memberType !== 'Candidate' &&
                <div className="mt-5">
                  <StreakBar 
                    memberId={member?.MemberId}
                    source="dashboard"
                  />
                </div>
            }
            <DemoNps />
            {memberType === 'Candidate' && (<DashboardCandidate />)}
            {memberType === 'Student' && (<DashboardStudent />)}
            {memberType === 'InactiveStudent' && (<DashboardInactiveStudent/>)}
          </>
      )}
    </div>
  )
}

export default Dashboard