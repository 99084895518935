import { fetchApi } from '../index';

export const GetProductCategory = async (token, lang) => {
    const url = '/market/speaking_lesson/product_category/';
 
    const payload = {
        payload: {
            brandId: parseInt(process.env.REACT_APP_BRAND_ID),
        },
        lang: lang,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const GetCategoryProducts = async (productCategoryId, memberStatus) => {

    const url = '/_services/member/v3/market/get_product_category_details/';

    const payload = {
        brandId: +process.env.REACT_APP_BRAND_ID,
        memberStatus: +memberStatus,
        productCategoryId: productCategoryId,
    };

    return await fetchApi(url, payload);
}


export const CheckDiscountCode = async (discountCode, memberId, memberStatus, productId, languageTitleType) => {
    const url = '/market/speaking_lesson/check_discount_code/';

    const payload = {
        payload: {
            campaignCode: discountCode,
            memberId: memberId,
            status: memberStatus,
            productId: productId,
            languageTitleType: languageTitleType
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: "",
    };

    return await fetchApi(url, payload);
}

export const PaySpeakingLessonPackage = async (payInfo, token) => {
    const url = '/market/speaking_lesson/pay_package/';
 
    const payload = {
        payload: payInfo,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const AddTerm = async (termInfo, token) => {
    const url = '/market/speaking_lesson/add_term/';
 
    const payload = {
        payload: termInfo,
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const AddStudentInformation = async (studentInfo, token) => {
    const url = '/market/speaking_lesson/add_student_information/';
 
    const payload = {
        payload: studentInfo,
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const GetMemberCampaigns = async (memberId, languageTitleType) => {

    const url = '/_services/member/v1/campaign/member_campaign_list/';

    const payload = {
        memberId: memberId,
        languageTitleType: languageTitleType
    }

    return await fetchApi(url, payload);
}

export const GetAutoApplyCampaigns = async (languageTitleType, productId) => {

    const url = '/_services/member/v1/campaign_market_detail/get_auto_apply_campaigns/';

    const payload = {
        languageTitleType: languageTitleType,
        productId: productId
    };

    return await fetchApi(url, payload);
}

export const GetStudentInvoiceInformation = async (memberId) => {

    const url = '/_services/member/v1/accounting_current_card/student_invoice_information/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}