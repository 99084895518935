import {decodeToken, encodeToken, getOS} from "../../components/Utility";
import {useEffect, useState} from "react";
import {OperatingSystems} from "../../components/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {url} from "../../routes/utility";
import {useDispatch} from "react-redux";
import {AutoLoginService, VerifyMagicLink} from "../../services/Auth/AuthService";
import {memberLogin} from "../../store/MemberSlice";

const Launch = () => {

    const { base64 } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [magicLinkJwt, setMagicLinkJwt] = useState("");

    const navigateByOs = () => {
        const os = getOS();

        switch (os) {
            case OperatingSystems.IOS:
                window.open("https://apps.apple.com/us/app/alingliziah/id1216593266", "_blank");
                break;
            case OperatingSystems.ANDROID:
                window.open("https://play.google.com/store/apps/details?id=com.konusarakogren.m.konusarakogrenmobil_ar&hl=tr&gl=US", "_blank");
                break;
            default:
                break;
        }

        navigate(url("dashboard"));
    }

    useEffect(() => {
        VerifyMagicLink(base64)
            .then(result => {
                let resultContent = JSON.parse(result);

                if(resultContent.status === 200){
                    setMagicLinkJwt(magicLinkJwt =>  resultContent.content);
                } else {
                    navigate(url("auth.login"));
                }
            });
    }, []);

    useEffect(() => {
        let abortController = new AbortController();

        if(magicLinkJwt.length > 0){

            AutoLoginService(magicLinkJwt).then(result => {
                if (result.statusCode !== 200) {
                    navigate(url("auth.login"));
                } else {
                    const member = decodeToken(magicLinkJwt);
                    let terms = '';

                    if (member.hasOwnProperty("Terms")) {
                        terms = encodeToken(member.Terms);
                        delete member["Terms"];
                    }

                    const token = encodeToken(member);

                    //set member login
                    dispatch(memberLogin({ token, terms }));

                    navigateByOs();
                }
            });
        }

        return () => {
            abortController.abort();
        };

    }, [magicLinkJwt]);
}

export default Launch;