import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import useAnalytics from "../hooks/useAnalytics";
import {DownArrow} from "../assets/svg";
import {getApplicationDirection} from "./Utility";
import {useSelector} from "react-redux";

const Accordion = (props) => {

    const {
        title,
        children,
        isOpen = false,
        disableOutsideClick = false,
        contentClassNames,
        scrollValue = 0,
        eventObject = {},
        isScrollable = true,
        isDownArrowIcon = false,
        isOpenCloseIcon = true,
        openCloseCheckboxStatus = null,
        isButton = false,
        action = () => {}
    } = props;

    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const childrenRef = useRef();
    const scrollRef = useRef();
    const firstVisit = useRef(true);

    const [open, setOpen] = useState(isOpen);

    const handleTransitionEnd = () => {
        if (!open) {
            childrenRef?.current?.classList.add('hidden');
        }
    };

    useEffect(() => {
        if (open) {
            childrenRef?.current?.classList.remove('hidden');
            //Açılma eventinde istenen log atılıyor.
            if(Object.keys(eventObject).length > 0)
                analytics.useAnalytics(eventObject.event, eventObject.params);
        }
    }, [open]);

    useEffect(() => {
        scrollRef.current.scrollTop = scrollValue;
    }, [scrollValue])

    return (
        <OutsideClickHandler onOutsideClick={() => { !disableOutsideClick && setOpen(open => false) }}>
            <div className="outline-none text-sm mt-5 mb-5">
                <button
                    className={classNames(`${contentClassNames} flex justify-between border rounded-[10px] px-4 py-[7px] items-center transition  duration-500 cursor-pointer relative group-focus-within:border-b-0 border-box w-full pr-1`, {
                        "rounded-b-none bg-[#fafafa]" : open,
                        "bg-white" : !open,
                        "pr-7" : isOpenCloseIcon && getApplicationDirection(language),
                        "pl-7" : isOpenCloseIcon && !getApplicationDirection(language),
                        "pr-2" : !isOpenCloseIcon && getApplicationDirection(language),
                        "pl-2" : !isOpenCloseIcon && !getApplicationDirection(language)
                    })}
                    onClick={() => {
                        if(!isButton)
                            setOpen(open => !open);

                        action();
                        firstVisit.current = false
                    }}
                >
                    {isOpenCloseIcon &&
                        <div
                            className={classNames("items-center inline-flex justify-center transform transition duration-500 absolute mr-auto h-full", {
                                "!rotate-90": open,
                                "left-0 ml-2" : !getApplicationDirection(language),
                                "right-0 mr-2 rotate-180" : getApplicationDirection(language)
                            })}
                        >
                            <span className={classNames("material-symbols-outlined transition-colors duration-300", {
                                "text-primary": open,
                                "text-base-text": !open
                            })}>
                                arrow_forward_ios
                            </span>
                        </div>
                    }
                    <div
                        className={classNames("", {
                            "mr-2" : getApplicationDirection(language),
                            "ml-2" : !getApplicationDirection(language)
                        })}
                    >
                        <p className={classNames("font-bold text-start transition-colors duration-300", {
                            "text-primary": open
                        })}>
                            {title}
                        </p>
                    </div>
                    {(isDownArrowIcon && !open && openCloseCheckboxStatus === null) &&
                        <div
                            className={classNames("", {
                                "mr-2" : isOpenCloseIcon && getApplicationDirection(language),
                                "ml-2" : isOpenCloseIcon && !getApplicationDirection(language)
                            })}
                        >
                            <img src={DownArrow} className="-rotate-90" width={14} height={14} alt="down-arrow" />
                        </div>
                    }

                    {openCloseCheckboxStatus !== null &&
                        <div
                            className={classNames("flex text-center justify-center w-5 h-5 rounded-full", {
                                "bg-[#c5c5c5]" : !openCloseCheckboxStatus,
                                "bg-green": openCloseCheckboxStatus,
                                "ml-2" : getApplicationDirection(language),
                                "mr-2" : !getApplicationDirection(language)
                            })}
                        >
                            <span className="material-symbols-outlined text-white text-[22px]">check</span>
                        </div>
                    }
                </button>
                <div
                    ref={scrollRef}
                    className={classNames(`${contentClassNames} transition-all duration-500 border-box rounded-[10px] rounded-t-none px-4 bg-[#fafafa]`, {
                        "border-x border-b pt-2" : open,
                        "overflow-y-scroll max-h-screen" : isScrollable && open,
                        "max-h-0": !open
                    })}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {open && (
                        <div
                            ref={childrenRef}
                            className={`${isScrollable ? 'max-h-[600px]' : ''} ${!open && 'animate-fadeOut'} ${firstVisit.current ? 'hidden' : ''}`}
                        >
                            {children}
                        </div>
                    )}
                    {!open && <></>}
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default Accordion;