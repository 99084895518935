import React from "react";
import { useSelector } from "react-redux";
import { getApplicationDirection } from "../../../../components/Utility";
import classNames from "classnames";
const ExerciseQuestionStep = (props) => {

    const {
        currentQuestion,
        totalQuestionCount,
        closeButtonAction = () => {}
    } = props;

    const language = useSelector(state => state.localizationStore.language);

    const direction = getApplicationDirection(language) ? 'right' : 'left';

    return (
        <div className="relative">
            <div className="relative h-2 mt-6 w-[95%] md:w-[98%]">
                {[...Array(totalQuestionCount)].map((_, index) => (
                    <div
                        key={index}
                        className={`absolute top-0 bottom-0 left-0 rounded-full overflow-hidden ${
                            index < currentQuestion ? 'bg-box' : 'bg-box'
                        }`}
                        style={{
                            [direction]: `calc(${(index / totalQuestionCount) * 100}% + 1px)`,
                            width: `calc(${100 / totalQuestionCount}% - ${(totalQuestionCount - 1) / totalQuestionCount}px)`,
                        }}
                    >
                        <div 
                            className={`h-full bg-green transition-all duration-500 ease-out ${
                                index < currentQuestion ? 'w-full' : 'w-0'
                            }`}
                        ></div>
                    </div>
                ))}
            </div>
            <button
                type="button"
                className={classNames("absolute -top-2", {
                    "-left-2 md:-left-3": getApplicationDirection(language),
                    "-right-2 md:-right-3": !getApplicationDirection(language)
                })}
                onClick={closeButtonAction}
            >
                <span className="material-symbols-outlined cursor-pointer hover:text-base-text/90">close</span>
            </button>
        </div>
    );
};

export default ExerciseQuestionStep;