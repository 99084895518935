import React from 'react'
import { Outlet } from 'react-router-dom'
import { useLocalization } from '../../hooks/useLocalization';
import { Logo } from '../../assets/svg';
import { getApplicationDirection } from '../../components/Utility';
import { useSelector } from 'react-redux';
import useAuth from "../../hooks/useAuth";
import {MemberStatus} from "../../components/Constants";

const OnboardingLayout = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    return (
        <section className="sm:min-h-full flex overflow-hidden flex-col lg:flex-row">

            {/*<!-- Left -->*/}
            <div className="left-onboarding">
                <div className="lg:h-screen flex items-center justify-end xs:justify-center lg:justify-start">
                    <p className={`${getApplicationDirection(language) ? 'mr-[20%]' : 'ml-[20%]'} z-10 xs:mr-0 max-lg:mt-6 max-lg:mb-[35px]`}>
                        {strings.onboarding.welcome_1}<br />
                        {+member.Status === MemberStatus.Candidate
                            ? strings.onboarding.welcome_2
                            : strings.onboarding.welcome_2_single}
                    </p>
                    <img className={`${getApplicationDirection(language) ? '-rotate-[46.5deg] -right-[38px] lg:-right-[75px] !top-2 lg:top-[13%]' : 'rotate-[46.5deg] -left-[38px] lg:-left-[75px] !-top-2 lg:!top-[13%]'} absolute w-[110px] lg:w-[220px]`} src={Logo} alt="logo" />
                </div>
            </div>

            {/*<!-- Right -->*/}
            <Outlet />

        </section>
    )
}

export default OnboardingLayout