import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import { Button, ModalOneButton, ModalOverlay, ModalTwoButtons, closeModal, getyyyyMMddDate, openModal } from "../../../components";
import { getApplicationDirection, parseDateForLesson, parseDateForLessonTurkishCulture } from '../../../components/Utility';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetLessonDatesService } from "../../../services/SpeakingLesson/SpeakingLessonService";
import { PostponeLessonService } from '../../../services/Support/SupportService';
import {EventLogs} from "../../../components/Constants";
import usePostponeButtonVisibility from "../../../hooks/usePostponeButtonVisibility";
import {Link} from "react-router-dom";
import {url} from "../../../routes/utility"
import {InformationAccordion} from "../components";

const Postpone = ({ data, lesson, termId, getHistoryData, startDate = false }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);
    const buyPostponePageVisibility = usePostponeButtonVisibility();
    const { member, token } = useAuth();
    const localStorageTermId = localStorage.getItem('selectedTerm');

    const [dateValue, setDateValue] = useState(new Date());
    const [minDateValue, setMinDateValue] = useState(false);
    const [error, setError] = useState();
    const [lessonDates, setLessonDates] = useState([]);

    const [firstPermission, setFirstPermission] = useState(false);
    const [secondPermission, setSecondPermission] = useState(false);
    const [postponeRightCount, setPostponeRightCount] = useState(null)

    const modalRef = useRef();
    const secondModalRef = useRef();
    const thirdModalRef = useRef();
    const modalOverlayRef = useRef();

    const colorByLessonType = [
        { status: 1, color: 'bg-active', name: strings.lesson_type.active },
        { status: 2, color: 'bg-attended', name: strings.lesson_type.attended },
        { status: 3, color: 'bg-missed', name: strings.lesson_type.missed },
        { status: 4, color: 'bg-postponed', name: strings.lesson_type.postponed },
        { status: 5, color: 'bg-frozen', name: strings.lesson_type.frozen },
        { status: 6, color: 'bg-holiday', name: strings.lesson_type.holiday },
        { status: 7, color: 'bg-weekend', name: strings.lesson_type.weekend },
        { status: 8, color: 'bg-no-lesson', name: strings.lesson_type.no_lesson },
        { status: 9, color: 'bg-system-postponed', name: strings.lesson_type.system_postponed },
        { status: 10, color: 'bg-deleted', name: strings.lesson_type.deleted }
    ]
  
  
    const addGroupClass = ({ date, view }) => {
      if (view === 'month') {
        return 'group';
      }
    }
  
    const getTileContent = (date, view) => {
  
      const todayDate = new Date();
      return (
        <div className="cell-content">
          <div className="relative group overflow-visible">
            {lessonDates.length > 0 && view === 'month' && lessonDates.map((lesson, index) => {
              const lessonStatusColor = colorByLessonType.filter(data => data.status === lesson.status);
              let color = lessonStatusColor[0].color;
              if (lesson.lessonDate === getyyyyMMddDate(date) && getyyyyMMddDate(todayDate) !== lesson.lessonDate && getyyyyMMddDate(dateValue) !== lesson.lessonDate) {
                return (
                  <div key={index}>
                    <div className={`absolute -bottom-[18px] w-[5px] h-[5px] rounded-full ${color} ${getApplicationDirection(language) ? '!-right-[18px]' : '!-left-[18px]'}`}>
                    </div>
                    <div className={`tooltip-top ${getApplicationDirection(language) ? '!-right-[130px]' : '!-left-[130px]'}`}>
                        <p>{lessonStatusColor[0].name}</p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      );
    }

    useEffect(() => {
        let abortController = new AbortController();
    
        async function getLessonDates() {
          return await GetLessonDatesService(termId, token);
        }
    
        if (localStorageTermId !== null) {
          getLessonDates().then(data => {
            if (data.statusCode === 200) {
              setLessonDates(lessonDates => JSON.parse(data.message));
            }
          })
        }
    
        return () => {
          abortController.abort();
        }
      }, [termId])

    //set error
    const errorList = [
        { 'term not found!': strings.support.error_list.term_not_found },
        {
            'Lesson date must be equal or greater than today':
                strings.support.error_list.lesson_date_must_be_equal_or_greater_than_today,
        },
        { 'lessontime is invalid!': strings.support.error_list.lesson_time_is_invalid },
        {
            'Active or postponed lesson not found with specified date!':
                strings.support.error_list.active_or_postponed_lesson_not_found_with_specified_date,
        },
        {
            'Postpone right is already used for this date!':
                strings.support.error_list.postpone_right_is_already_used_for_this_date,
        },
        {
            'You are not in the postponement time range!':
                strings.support.error_list.you_are_not_in_the_postponement_time_range,
        },
        {
            'You do not have enough postpone right!':
                strings.support.error_list.you_do_not_have_enough_postpone_right,
        },
    ];

    const generalInformationData = [
        { id: 1, text: strings.support.postpone_lesson_information.information_one },
        { id: 2, text: strings.support.postpone_lesson_information.information_two },
        { id: 3, text: strings.support.postpone_lesson_information.information_three },
        { id: 4, text: strings.support.postpone_lesson_information.information_four },
        { id: 5, text: strings.support.postpone_lesson_information.information_five },
        { id: 6, text: strings.support.postpone_lesson_information.information_six },
    ];

    const errorTranslation = (err) => {
        for (const error of errorList) {
            if (Object.keys(error)[0] === err) {
                const errorValue = Object.values(error)[0];
                return Array.isArray(errorValue) ? errorValue[0] : errorValue;
            }
        }
        return null;
    };

    const postponeRequest = async (date, memberId) => {

        const result = await PostponeLessonService(date, termId, memberId);

        if(result.status === 200) {
            setError(strings.support.postpone_lesson.third_modal_message);
            analytics.useAnalytics(EventLogs.POSTPONE_LESSON_COMPLETED);
            getHistoryData(termId);
        } else {
            const errMsg = errorTranslation(result.content);

            if (errMsg) {
                setError(errMsg);
            } else {
                setError(strings.general_information_messages.an_error_occured);
            }

            analytics.useAnalytics(EventLogs.POSTPONE_LESSON_OPERATION_FAILED);
        }
    };

    useEffect(() => {
        //close first and second modal, open third modal
        if (firstPermission && secondPermission) {
            closeModal(modalOverlayRef, modalRef)
            closeModal(modalOverlayRef, secondModalRef)
            openModal(modalOverlayRef, thirdModalRef)
            postponeRequest(parseDateForLesson(dateValue), member?.MemberId);
        }

    }, [firstPermission, secondPermission])


    //if there is a transaction begin date set min date with it
    useEffect(() => {
        let minDate = new Date();

        if (startDate) {
            minDate = new Date(startDate);
            setDateValue(dateValue => minDate);
        }

        setMinDateValue(dateValue => minDate);

    }, []);

    useEffect(() => {
        let postponeRight = "يحق لكم تأجيل #count# درسا."
        postponeRight = postponeRight.replace("#count#", data.postponeRightCount);
        setPostponeRightCount(postponeRight)
    },[data])

    return (
        <>
            <InformationAccordion
                title={strings.support.postpone_lesson_information.information_title}
                generalInformationData={generalInformationData}
            />
            <div className="mx-auto text-center mt-7 text-sm px-4">
                <div className="mt-7">
                    <p className="mb-4" dangerouslySetInnerHTML={{ __html: strings.support.postpone_lesson.postpone_right.replace("#counter#", data.postponeRightCount) }} />
                </div>
                {buyPostponePageVisibility && (
                    <div className='btn-outer-div'>
                        <Link
                            to={url("market.rightcatalog")}
                            state={{ "token": token }}
                            className="button button-mt primary-button-outline"
                        >
                            {strings.support.postpone_lesson.buy_postpone_lesson}
                        </Link>
                    </div>
                )}
                <p className="mt-5">
                    {strings.support.postpone_lesson.postpone_information}{" "}
                </p>
                <p className="font-bold mt-5">
                    {strings.support.postpone_lesson.choose_postpone_date}
                </p>
            </div>

            {minDateValue && (
                <div className="my-5 flex justify-center">
                    <Calendar
                        locale={language === 'tr' ? "tr-TR" : "en-EN"}
                        value={dateValue}
                        onChange={setDateValue}
                        tileContent={({ date, view }) => getTileContent(date, view)}
                        tileClassName={addGroupClass}
                        minDate={minDateValue}
                    />
                </div>
            )}

            <div className="btn-outer-div">
                <Button
                    classnames="button primary-button my-7"
                    action={() => {
                        analytics.useAnalytics(EventLogs.POSTPONE_LESSON_BUTTON_CLICKED);
                        openModal(modalOverlayRef, modalRef)
                    }}
                    text={strings.support.postpone_lesson.postpone}
                />
            </div>

            <p className='text-center'>
                <span className='font-bold'>{strings.ai_chatbot.note}:{" "}</span>
                {strings.support.postpone_lesson.postpone_lesson_count_description
                    .replace("#count#", data.productDayCount)
                }
            </p>

            {/* Modal */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                title={strings.modal.transaction_information}
                message={strings.support.postpone_lesson.modal_1_content.replace(/#date#/g, parseDateForLessonTurkishCulture(dateValue)).replace(/#program#/g, lesson)}
                overlayRef={modalOverlayRef}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    setFirstPermission(firstPermission => true);
                    analytics.useAnalytics(EventLogs.POSTPONE_LESSON_STEP_ONE);
                    closeModal(modalOverlayRef, modalRef);
                    openModal(modalOverlayRef, secondModalRef);
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setFirstPermission(firstPermission => false);
                    setSecondPermission(secondPermission => false);
                }}
            />

            <ModalTwoButtons
                ref={secondModalRef}
                title={strings.modal.transaction_information}
                message={strings.support.postpone_lesson.second_modal_message}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                overlayRef={modalOverlayRef}
                buttonClick1={() => {
                    analytics.useAnalytics(EventLogs.POSTPONE_LESSON_STEP_TWO);
                    setSecondPermission(secondPermission => true);
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, secondModalRef);
                    setFirstPermission(firstPermission => false);
                    setSecondPermission(secondPermission => false);
                }}
            />

            <ModalOneButton
                ref={thirdModalRef}
                title={strings.modal.information_message}
                message={error}
                buttonText={strings.modal.okey}
                overlayRef={modalOverlayRef}
                buttonClick={() => {
                    setFirstPermission(firstPermission => false);
                    setSecondPermission(secondPermission => false);
                    closeModal(modalOverlayRef, thirdModalRef);
                    setError(null);
                }}
            />
        </>
    )
}

export default Postpone