import classNames from 'classnames';
import { getCookie } from 'ko-basic-cookie';
import React, { useEffect, useRef, useState } from 'react';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import { PasswordEye, PasswordEyeSlash } from '../../assets/svg';
import { decodeToken, encodeToken, ModalOneButton, ModalOverlay } from '../../components';
import { cookieName, EventLogs, TermStatusTypes } from '../../components/Constants';
import { getApplicationDirection, getBrowserName } from '../../components/Utility';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { GetCandidateSurveyService, LoginService } from '../../services/Auth/AuthService';
import {
    checkPassword,
    clearError,
    closeModal,
    openModal,
    showError,
    validPhoneNumber
} from '../../services/Auth/AuthValidation';
import { setAuth } from '../../store/AuthSlice';
import { memberLogin } from '../../store/MemberSlice';
import { setTermId } from "../../store/TermSlice";
import { getQueryParam } from '../../utils/Static';

const Login = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const passwordRef = useRef();
    const phoneErrorRef = useRef();
    const passwordErrorRef = useRef();
    const phoneInputRef = useRef();
    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [value, setValue] = useState({ iso2: 'sa', dialCode: '966', phone: '' });
    const [phoneNumber, setPhoneNumber] = useState('+');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)

    const token = getCookie(cookieName) || false;
    const language = useSelector(state => state.localizationStore.language);

    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    //intl tel input values
    let inputProps = { placeholder: value.iso2 === 'sa' ? '1X XXX XXXX' : strings.auth.form.enter_your_phone_number };
    const intlTelOpts = { preferredCountries: ['sa', 'iq', 'kw', 'ae', 'om', 'qa', 'bh', 'ly', 'eg', 'ma', 'tn', 'dz', 'sy', 'sd', 'lb', 'ps', 'tr'] };
    const phoneNumberHandle = val => {
        if (val.phone.startsWith('0')) {
            val.phone = val.phone.slice(1);
        }
        if (val.dialCode !== value.dialCode) {
            setValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
        } else {
            setValue(val);
        }
    };

    const passwordHandle = e => {
        setPassword(password => e.target.value);
    };

    const showPasswordHandle = () => {
        setShowPassword(showPassword => !showPassword)
    }

    const submitHandle = async e => {
        e.preventDefault();


        //handle operations
        if (validPhoneNumber(phoneNumber) && checkPassword(password)) {

            setLoading(loading => true)

            const result = await LoginService(phoneNumber, password, getBrowserName());

            if (result.statusCode === 200) {
                //get member info
                const rawToken = result.message;
                const member = decodeToken(rawToken);

                /*
                    Kullanıcı giriş yaparken ilk aktif dönemi set ediliyor.
                    Bu sayede çıkış yaparken pasif/onay bekleyen/gelecek bir dönem seçtiyse kafa karışıklığı önlenmiş oluyor.
                */
                const activeTerm = member?.Terms && JSON.parse(member?.Terms).find(term => term.Status === TermStatusTypes.Active);
                activeTerm && dispatch(setTermId(activeTerm.TermId));
                activeTerm && localStorage.setItem("selectedTerm", JSON.stringify(activeTerm.TermId));

                let terms = '';

                if (member.hasOwnProperty("Terms")) {
                    terms = encodeToken(member.Terms);
                    delete member["Terms"];
                }

                const token = encodeToken(member);

                dispatch(memberLogin({ token, terms }));

                const surveyResult = await GetCandidateSurveyService(member?.MemberId);
                const surveyStatus = JSON.parse(surveyResult.content);

                if (surveyStatus && surveyStatus?.second.length === 0) {
                    navigate(url("onboarding.first") + window.location.search)
                }
                // Eğer bir familyCode varsa dashboard yerine joinfamily ekranına yönlendiriyoruz.
                 else if (familyCode) navigate(url("mobile.joinfamily") + window.location.search);
                 else navigate(url("dashboard"));
            } else {
                if (result.message) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.message}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                openModal(modalOverlayRef, modalRef);
            }
            setLoading(loading => false)
        } else {
            //if there are errors show them
            showError(validPhoneNumber(phoneNumber), phoneErrorRef);
            showError(checkPassword(password), passwordErrorRef);
        }
    }

    useEffect(() => {
        if(value.dialCode === '90' && value.phone.length === 1 && value.phone === '0') {
            setValue({ iso2: 'tr', dialCode: '90', phone: '' })
        }
    }, [value]);

    //phone number use effect
    useEffect(() => {

        clearError(phoneErrorRef);
        clearError(passwordErrorRef);
        //set phone number
        let phoneNum = '+' + value.dialCode + value.phone;
        setPhoneNumber(phoneNumber => phoneNum);

        //for borders
        const element = phoneInputRef.current.children[1];

        if (value.phone === '') {
            element.classList.remove('border-red-600')
            element.classList.remove('border-green-600')
        } else {
            if (validPhoneNumber(phoneNum)) {
                element.classList.remove('border-red-600')
                element.classList.add('border-green-600')
            }
            else {
                element.classList.add('border-red-600')
                element.classList.remove('border-green-600')
            }
        }

    }, [value])

    //password eye
    useEffect(() => {
        passwordRef.current.type = showPassword ? 'input' : 'password';
    }, [showPassword])

    //password useeffect
    useEffect(() => {
        clearError(passwordErrorRef);

        //for borders
        const element = passwordRef.current;

        if (password === '') {
            element.classList.remove('border-red-600')
            element.classList.remove('border-green-600')
        } else {
            if (checkPassword(password)) {
                element.classList.remove('border-red-600')
                element.classList.add('border-green-600')
                clearError(passwordErrorRef);
            }
            else {
                element.classList.add('border-red-600')
                element.classList.remove('border-green-600')
            }
        }

    }, [password])

    //Çıkış yapıldığında analytics'e user_prop'ları göndermek için giriş yapılma durumu false'a çekiliyor.
    useEffect(() => { 
        dispatch(setAuth(false));
    }, [token])

    //login control & analytics
    useEffect(() => {
        if (token) {
            analytics.useAnalytics(EventLogs.LOGIN_SUCCESSFUL);
            navigate(url("home"))
        }
    }, [token]);

    return (
        <>
            <form 
                id="form" 
                className='font-bold'
                onSubmit={submitHandle}
            >
                <div className="mb-[6px]" ref={phoneInputRef}>
                    <label className="block text-start text-base-text-light" htmlFor="phone">
                        {strings.auth.form.phone_number}
                    </label>
                    <ReactIntlTelInput className="keypress input-area w-full"
                        inputProps={inputProps}
                        intlTelOpts={intlTelOpts}
                        value={value}
                        onChange={phoneNumberHandle}

                    />
                </div>
                <p id="phone_error" className="mb-2 text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={phoneErrorRef}>{strings.auth.validation_messages.invalid_phone}</p>
                <div className="mb-[6px] relative">
                    <label className="block text-start text-base-text-light" htmlFor="password">
                        {strings.auth.form.your_password}
                    </label>
                    <input className="keypress input-area w-full block" id="password" type="password" ref={passwordRef} name="password" placeholder={strings.auth.form.enter_your_password}
                        onChange={passwordHandle}
                    />
                    <span className={`toggle-password ${getApplicationDirection(language) ? 'left-3' : 'right-3'}`}>
                        <img src={showPassword ? PasswordEyeSlash : PasswordEye} width="20" alt="" onClick={showPasswordHandle} />
                    </span>
                </div>
                <p id="password_error" className="text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={passwordErrorRef}>{strings.auth.validation_messages.invalid_password}</p>

                <button type="submit" disabled={loading} className={classNames("button button-mt primary-button", {
                    "opacity-25 disabled:pointer-events-none": loading
                })}>
                    {loading ?
                        strings.general_information_messages.processing
                        : strings.auth.form.sign_in_button}
                </button>
                <Link to={{
                    pathname: url('auth.register'),
                    search: window.location.search
                }}
                    className="button button-mt primary-button-outline">{strings.auth.form.sign_up_button}</Link>
                <Link to={{
                    pathname: url('auth.forgotpassword'),
                    search: window.location.search
                }}
                    className="button button-mt text-button text-base-text-light">{strings.auth.form.forgot_password_button}</Link>
            </form>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.auth.validation_messages.invalid_username_or_password}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default Login


